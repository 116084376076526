import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import SellModal from "../../../components/SellModal/SellModal";
import styles from "./location.module.scss";
import allCountries from "../../../constants/data.json";
import { useEffect, useMemo, useState } from "react";
import { LocationProps } from "../../../interfaces/salePost";
import SelectShared from "../../../components/Select/Select";
import { trackEvent } from "../../../config/firebase";

const Location = ({
    setCountry,
    setRegion,
    country,
    region,
    cleanPlateRepeated,
    plate,
    isFoundPlate
}: LocationProps) => {
    const navigate = useNavigate();
    const [countrySelected, setCountrySelected] = useState<string>("Chile");
    const [regionSelected, setRegionSelected] = useState<string>("Región Metropolitana de Santiago");

    const handleChangeCountry = (name: string) => {
        setCountrySelected(name);
    }

    const handleChangeRegion = (name: string) => {
        setRegionSelected(name);
    }

    const formattedOptionsCountries = useMemo(() => allCountries.map(
        (opt, index) => ({
            id: index.toString(),
            name: opt.countryName,
            regions: opt.regions,
        })
    ), []);

    const formattedOptionsRegions = useMemo(() => {
        let availableRegions: any = []
        const countryFilter = allCountries.find(
            item => item.countryName === countrySelected,
        );

        if (countryFilter) {
            countryFilter.regions.map((opt, index) => {
                if (opt?.name === 'Región Metropolitana de Santiago' || opt?.name === 'Valparaíso') {
                    availableRegions.push(
                        ({
                            id: index.toString(),
                            name: opt.name,
                        })
                    );
                }
            });
            return availableRegions;
        } else {
            return [];
        }
    }, [countrySelected]);

    const onPressBack = () => {
        cleanPlateRepeated();
        if (isFoundPlate) {
            navigate(`/sell/car_search_information`);
            return;
        }
        navigate("/sell/search");
    }

    const onPressNext = () => {
        setCountry(countrySelected);
        setRegion(regionSelected);
        if (isFoundPlate) {
            navigate("/sell/car_detail");
            return;
        }
        navigate("/sell/brand");
    }

    useEffect(() => {
        if (country) setCountrySelected(country);
        if (region) setRegionSelected(region);
    }, [country, region])

    useEffect(() => {
        if (!plate) return navigate("/sell");
    }, [plate]);

    useEffect(() => {
        trackEvent("venta-paso-ubicación")
    }, [])

    return (
        <SellModal
            step={isFoundPlate ? 2 : 1}
            title="Ingresa tu ubicación"
            onClickBack={onPressBack}
            isFoundPlate={isFoundPlate}
        >
            <div className={styles.inputsContainer}>
                <SelectShared
                    label="Pais"
                    name="country"
                    value={countrySelected}
                    onChange={handleChangeCountry}
                    options={formattedOptionsCountries}
                    disabled
                    keyValue="name"
                    arrow={false}
                    className={styles.input}
                />
                <SelectShared
                    label="Región"
                    name="region"
                    value={regionSelected}
                    onChange={handleChangeRegion}
                    options={formattedOptionsRegions}
                    keyValue="name"
                    optionsEmptyMessage="Debes seleccionar un pais"
                    className={styles.input}
                />
            </div>
            <Button disabled={!regionSelected || !countrySelected} title="Continuar" primary onPress={onPressNext} className={styles.submitButton} />
        </SellModal>
    )
};

export default Location;