import { useMemo, useEffect } from "react";
import styles from "./brand.module.scss";
import { useNavigate } from "react-router-dom";
import { BrandsPageProps } from "../../../interfaces/salePost";
import SelectShared from "../../../components/Select/Select";
import SellModal from "../../../components/SellModal/SellModal";
import { useToast } from "../../../helpers/hooks";
import { trackEvent } from "../../../config/firebase";

const Brand = ({
    getBrands, 
    brands, 
    cleanGetBrands,
    setBrand, 
    plate,
    isFoundPlate
}: BrandsPageProps) => {
    const navigate = useNavigate();
    const toast = useToast();

    const brandsNormalized: any = useMemo(() => {
        return brands?.data && brands?.data.map((brand: any) => (
            {
                id: brand?.brand_id,
                name: brand?.name
            }
        ))
    }, [brands?.data]);

    const onPressNext = (brand: any) => {
        setBrand({id: brand?.id, label: brand?.name});
        navigate("/sell/year");
    }

    useEffect(() => {
        if(brands?.fetching === "failure"){
            toast("Ha ocurrido un error al traer las marcas");
            cleanGetBrands();
        }
        if(brands?.fetching === "success"){
            cleanGetBrands();
        }
    }, [brands?.fetching]);

    useEffect(() => {
        if(!plate) return navigate("/sell");
    }, [plate]);
    
    useEffect(() => {
        getBrands();
        trackEvent("venta-paso-marca")
    }, []);
    
    return (
        <SellModal 
            step={2} 
            title="Ingresa la marca de tu auto"
            onClickBack={() => navigate("/sell/location")}
            className={styles.sellModal}
            isFoundPlate={isFoundPlate}
        >
            <div className={styles.inputsContainer}>
                <SelectShared
                    variant="scroll"
                    label="Escribe la marca que buscas"
                    name="brand"
                    onPressOption ={(value: any) => onPressNext(value)}
                    options={brandsNormalized}
                    loading={brands?.fetching === "loading"}
                    loadingMessage="Cargando marcas"
                    className={styles.input}
                />
            </div>
        </SellModal>
    )
};

export default Brand;