import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import SelectDocuments from "../../../components/SelectDocuments";
import SellModal from "../../../components/SellModal/SellModal";
import { images } from "../../../constants/images";
import { capitalize } from "../../../helpers/app";
import { CarSearchInformationProps } from "../../../interfaces/salePost";
import styles from "./carSearchInformation.module.scss";
import { useToast } from "../../../helpers/hooks";
import { trackEvent } from "../../../config/firebase";

const CarSearchInformation = ({
    plate,
    brands,
    models,
    year,
    transmission,
    model_image,
    vehicle_type,
    vin,
    color,
    getModels,
    cleanGetModels,
    setModel,
    isFoundPlate
}: CarSearchInformationProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const PLATE = plate.match(/(\w{2})/g)?.join("-") || plate;

    useEffect(() => {
        if(models?.fetching === "failure"){
            toast("Ha ocurrido un erro al buscar los modelos");
            cleanGetModels();
        }
        if(models?.fetching === "success"){
            cleanGetModels();
        }
    }, [models?.fetching]);

    useEffect(() => {
        if(models.selected && !models.selected?.id){
            getModels(brands.selected?.id);
        }
    },[models.selected]);

    useEffect(() => {
        if(!plate) return navigate("/sell");
    }, [plate]);

    useEffect(() =>{
        trackEvent("venta-paso-patente-encontrada")
    },[])

    return (
        <SellModal
            step={1}
            title={PLATE}
            onClickBack={() => navigate("/sell")}
            isFoundPlate={isFoundPlate}
        >
            <div className={styles.inputsContainer}>
                <div className={styles.carInformation}>
                    <img alt="SUV" src={model_image ?? images.suv} />
                    <p>{year && year.label} {brands && brands.selected?.label} {models.selected && models.selected?.label}</p>
                </div>
                <div className={`${styles.section} ${styles.caracteristics}`}>
                    <p className={styles.title}>Características</p>
                    <div className={styles.content}>
                        <div className={styles.caracteristic}>
                            <img src={images.carType} alt="Tipo" />
                            <p>
                                <span className={styles.title}>{capitalize("tipo")}</span>
                                <span className={styles.value}>{vehicle_type}</span>
                            </p>
                        </div>
                        <div className={styles.caracteristic}>
                            <img src={images.color} alt="Color" />
                            <p>
                                <span className={styles.title}>{capitalize("color exterior")}</span>
                                <span className={styles.value}>{color}</span>
                            </p>
                        </div>
                        <div className={styles.caracteristic}>
                            <img src={images.transmission} alt="Transmission" />
                            <p>
                                <span className={styles.title}>{capitalize("transmisión")}</span>
                                <span className={styles.value}>{transmission}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`${styles.section} ${styles.vin}`}>
                    <p className={styles.title}>VIN</p>
                    <div className={styles.content}>
                        <img alt="barCode" src={images.barCode} />
                        <p>{vin}</p>
                    </div>
                </div>
                {
                    !models.selected?.id &&
                    <div className={`${styles.section} ${styles.model}`}>
                        <p className={styles.title}>Modelo</p>
                        <div className={styles.content}>
                            <SelectDocuments 
                                onClickItem={(value: any) => setModel({id: value.model_id, label: value.name, name: value.name})} 
                                keyValue="model_id" 
                                className={styles.selectModel} 
                                options={models?.data} 
                                name="Modelos" 
                                label="Seleccionar Modelo" 
                            />
                        </div>
                    </div>
                }
                <div className={styles.bottom}>
                    <Button 
                        onPress={() => navigate("/sell/location")} 
                        title="Confirmar" 
                        primary 
                        className={styles.button}
                    />
                </div>
            </div>
        </SellModal>
    )
};

export default CarSearchInformation;