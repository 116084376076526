import axios from 'axios';
import { StoreState } from "./store";
import jwt from "jwt-decode";
import { refreshTokenFailure, refreshTokenSuccess } from '../reducers/auth';
let store: StoreState;
export const injectStore = (_store: any) => store = _store;
const requestQueue: any = [];
let isRefreshing = false;
let refreshPromise: Promise<any> | null = null;

export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

const enqueueRequest = (config: any, resolve: (value: any) => void, reject: (error: any) => void) => {
    requestQueue.push({ config, resolve, reject });
};

const processQueue = (token: string) => {
    requestQueue.forEach((req: any) => {
        const headers: any = req.config.headers || {}; // Ensure headers object exists
        if (token) {
            headers['access-token'] = token;
        }
        req.config.headers = headers; // Update the headers
        axiosClient(req.config)
        .then(req.resolve)
        .catch(req.reject);
    });
    requestQueue.length = 0;
};

const refreshTokenRequest = async () => {
    const refreshToken = store.getState().auth.refreshToken;
    if (!refreshToken) return;

    const response = await fetch(process.env.REACT_APP_API_TOKEN || "", {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${refreshToken}`,
        },
    });

    const json = await response.json();
    if (!json.success){
        const errorResponse = json?.error?.message;
        store.dispatch({ type: refreshTokenFailure, payload: errorResponse });
        throw json;
    }

    const { data: { token, refresh_token, user } } = json;
    const { exp }: any = jwt(token);

    store.dispatch({ 
        type: refreshTokenSuccess, 
        payload: { 
            token: token, 
            refreshToken: refresh_token, 
            expirationTime: exp, 
            refreshType: "",
            user: user
        } 
    });

    return token;
}

axiosClient.interceptors.request.use(async (config: any) => {
    // Se ejecuta antes de realizar la request
    const token = store.getState().auth.token;

    if(!config.headers["Content-Type"]){
        delete config.headers["Content-Type"];
    }

    if(token){
        config.headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...(config.headers || {}),
        };
    }
    
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosClient.interceptors.response.use(
    (response: any) => {
        return response;
    }, 
    async (error: any) => {
        const originalRequest = error.config;
        let access_token = "";

        if (error.response) {
            if(error.response.status === 401 && originalRequest.url === "users/access"){
                return Promise.reject(error);
            }
            if(error.response.status === 401 && error.response.data.error.message === "UNAUTHORIZED"){
                error.response.data.error.code = "";
                error.response.data.error.errors = "";
                error.response.data.error.message = "";
            }
            if((error.response.status === 401) && !originalRequest._retry){
                originalRequest._retry = true; // Add custom retry property
                if(!isRefreshing){
                    isRefreshing = true;
                    try{
                        // Make sure only one refresh request is active at a time
                        if(!refreshPromise){
                            access_token = await refreshTokenRequest();
                        }

                        axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
                        originalRequest._retry = undefined;
                        isRefreshing = false;
            
                        // Process the queued requests with the new token
                        processQueue(access_token);
            
                        return axiosClient(originalRequest); // Retry the original request

                    }catch(_error){
                        isRefreshing = false;
                        return Promise.reject(_error);
                    } finally {
                        refreshPromise = null; // Reset the refresh promise
                    }

                }else{
                    // Queue the request while the token is refreshing
                    return new Promise((resolve, reject) => {
                        enqueueRequest(originalRequest, resolve, reject);
                    });
                }
            }
            return Promise.reject(error);
        }
    }
);