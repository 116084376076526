import { createSlice } from "@reduxjs/toolkit";
import { FetchingTypes } from "../interfaces/types";

export interface initialStateFinancingProps {
    financing: FinancingProps[],
    fetching: FetchingTypes
}

const initialState: initialStateFinancingProps = {
    financing: [],
    fetching: "unknown"
}


const financingSlice = createSlice({
    name: 'financing',
    initialState,
    reducers: {
        getFinancingRequest: (state) => {
            state.fetching = "loading";
        },
        getFinancingSuccess: (state, action) => {
            state.fetching = "success";
            state.financing = action.payload
        },
        getFinancingFailure: (state) => {
            state.fetching = "failure";
        },
        cleanFinancing: (state) => {
            state.fetching = initialState.fetching;
            state.financing = initialState.financing;
        }
    }
});

export const {
    getFinancingRequest,
    getFinancingSuccess,
    getFinancingFailure,
    cleanFinancing
} = financingSlice.actions;

export default financingSlice.reducer;