import styles from "./modalValidationProfile.module.scss";
import ButtonShared from '../Button/Button';
import Modal from "../Modal/Modal";
import { ModalValidationDocumentsProps } from "../../interfaces/components";

const ModalValidationProfile = ({ open, setOpen, type, user, modalDescription = "" }: ModalValidationDocumentsProps) => {

    const onClose = () => {
        setOpen({ open: false, type: type });
    }

    return (
        <Modal open={open} variant={"white"}>
            <div className={styles.modalContainer}>
                <p className={styles.message}>
                <span className={styles.title}>¡Tu información es valiosa!</span>
                <span className={styles.description}>{modalDescription}</span>
                </p>
                <div className={styles.buttons}>
                    <ButtonShared
                        onPress={onClose}
                        title="Continuar"
                        primary
                        size="small"
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ModalValidationProfile;