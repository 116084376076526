import { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import { images } from "../../constants/images";
import { navOptions } from "../../constants/navigation";
import styles from "./navBar.module.scss";
import Notifications from "../../containers/Notifications/NotificationsContainer";
import { NavBarProps } from "../../interfaces/components";
import Dropdown from "../Dropdown";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { FaRegBell, FaBell } from 'react-icons/fa';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useWindowSize } from "use-hooks";
import { tabs } from "../../constants/sidebar";
import Badge from '@mui/material-next/Badge';
import { googleLogout } from '@react-oauth/google';
import { motion } from "framer-motion";
import { trackEvent } from "../../config/firebase";

const NavBar = ({
    user,
    notifications,
    logoutUser,
    cleanAllBuy,
    cleanExternalUser,
    openDownloadModalTop
}: NavBarProps) => {
    const location = useLocation();
    const { hash, pathname, search } = location;
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const [openMenu, setOpenMenu] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [currentRoute, setCurrentRoute] = useState("home")

    useEffect(() => {
        setCurrentRoute(location.pathname)
    },[location])

    const handleLogout = () => {
        cleanAllBuy();
        cleanExternalUser();
        googleLogout();
        logoutUser();
        navigate("/");
        setOpenMenu(false);
    };

    const onNavigateSell = () => {
        // navigate('/sell');
        setOpenMenu(false);
    };

    const onPressNotifications = () => {
        setOpenNotifications(prev => !prev);
        setOpenMenu(false);
    };

    const onPressProfile = () => {
        setOpenProfile(prev => !prev);
    };

    const onPressTab = (tab: any) => {
        navigate(`/profile/${tab?.navigate}`);
        setOpenMenu(false);
    };

    const onPressLink = () => {
        setOpenMenu(false);
    };

    const onPressSale = () => {
        setOpenMenu(false);
        trackEvent("click-vender");
    };

    return (
        <header className={`${styles.navBarContainer} ${pathname === '/' && styles.transparent} ${openDownloadModalTop && pathname === '/' && styles.modalTop}`}>
            <NavLink className={styles.linzeLink} to={"/"}>
                <img src={pathname === '/' ? images.logotipo : images.isoLogoTipo} className={styles.logo} alt="Logo" />
            </NavLink>
            <IconButton className={styles.menu} onClick={() => setOpenMenu(true)}>
                <MenuIcon className={`${styles.icon} ${pathname === '/' && styles.whiteColor}`} />
            </IconButton>
            <ul id="navBarOptions" className={`${styles.navBarLabels} ${openMenu && styles.open}`}>
                {
                    navOptions.map((option: any, index: number) => {
                        if (((option.to === "/profile" || option.to === "/notifications") && !user) || (option.to === "/login" && user) || (option.to === "/register" && user)) return;
                        if (option.to === "/notifications" && windowSize.width > 1024) {
                            let unreaded = 0;
                            return (
                                <li className={styles.notifications} key={index}>
                                    {
                                        notifications && notifications.forEach((item: any) => unreaded = (!item?.read) ? unreaded + 1 : unreaded)
                                    }
                                    {
                                        unreaded > 0 &&
                                        <Badge
                                            badgeContent={unreaded}
                                            color="error"
                                            classes={{
                                                root: styles.unReaded,
                                                badge: styles.badge
                                            }}
                                        />
                                    }
                                    <Dropdown
                                        type="icon"
                                        icon={openNotifications ? <FaBell className={`${styles.notificationsIcon} ${pathname==='/'&& styles.whiteColor}`} /> : <FaRegBell className={`${styles.notificationsIcon} ${pathname==='/'&& styles.whiteColor}`} />}
                                        onPress={onPressNotifications}
                                        className={styles.dropdown}
                                        id={`link-${option.id}`}
                                    >
                                        <Notifications user={user} />
                                    </Dropdown>
                                </li>
                            )
                        }
                        if (option.to === "/profile" && user) {
                            return (
                                <li className={styles.profile} key={index}>
                                    <div className={`${styles.picture} ${openProfile && styles.open}`}>
                                        <img src={user?.picture ?? images.profile} className={styles.image} alt="user" onClick={() => navigate("/profile")} />
                                        <Dropdown
                                            icon={
                                                <ArrowDropDownIcon className={`${styles.icon} ${pathname==='/'&& styles.whiteColor}`} />
                                            }
                                            onPress={onPressProfile}
                                            className={styles.dropdown}
                                            id={`link-${option.id}`}
                                        >
                                            <div className={styles.content}>
                                                {
                                                    tabs && tabs.map((tab: any, index: number) => (
                                                        <div key={index} className={styles.tab} onClick={() => onPressTab(tab)}>
                                                            <p>{tab?.label}</p>
                                                        </div>
                                                    ))
                                                }
                                                <div className={`${styles.tab} ${styles.blue}`} onClick={() => handleLogout()}>
                                                    <p>Cerrar sesión</p>
                                                </div>
                                            </div>
                                        </Dropdown>
                                    </div>
                                </li>
                            )
                        }
                        if (option.to) {
                            return (
                                <li className={`${styles.liLink} ${option.to === '/login' && styles.border}`} key={index}>
                                    <NavLink
                                        key={option.title}
                                        className={`
                                        ${pathname === '/' ? styles.whiteLink : styles.link}
                                        ${({ isActive }: any) => isActive ? styles.activeLink : undefined}
                                    `}
                                        id={`link-${option.id}`}
                                        to={option.to}
                                        title={option?.title}
                                        onClick={option?.id === "sell" ? onPressSale : onPressLink}
                                    >
                                        {option.title}
                                        {currentRoute === option.realPath ? 
                                        <motion.div 
                                            className={styles.currentRoutePoint}
                                            initial={{ opacity: 0, scale: 0.8 }} // Empieza con opacidad 0 y escala reducida
                                            animate={{ opacity: 1, scale: 1 }} // Termina con opacidad 1 y escala normal
                                            transition={{
                                                duration: 0.6, // Duración total de la animación
                                                ease: "easeOut", // Suaviza el fade
                                                opacity: { duration: 0.4 }, // Duración específica del fade-in
                                                scale: {
                                                type: "spring",
                                                stiffness: 500, // Mayor rigidez para un rebote más fuerte
                                                damping: 20, // Menor amortiguación para un efecto de rebote
                                                },
                                            }}
                                        /> : null}
                                    </NavLink>
                                </li>
                            )
                        }
                    })
                }
            </ul>
            <div className={`${styles.bkgr} ${openMenu && styles.open}`} onClick={() => setOpenMenu(false)}></div>
        </header>
    )
}
export default NavBar;