import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const FinancingCalculator = React.lazy(() => import("../../containers/FinancingCalculator/EntrypointContainer"));

export const financingCalculatorRoutes: RouteObject[] = [
    {
        path: "/financing-calculator",
        component: <FinancingCalculator />,
        title: "Calculadora de Financiamiento"
    }
];
