import styles from './button.module.scss';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { ButtonSharedProps } from '../../interfaces/components';

const ButtonShared = ({
    onPress = () => { },
    title,
    icon,
    rightIcon,
    className,
    primary = false,
    secondary = false,
    tertiary = false,
    fourtuary = false,
    disabled = false,
    loading = false,
    disableElevation = true,
    fontSize = "normal",
    size = "normal",
    id,
    toolTipTitle = "",
    spanClassName
}: ButtonSharedProps) => {
    return (
        <Tooltip disableFocusListener title={toolTipTitle} arrow placement="bottom">
            <span className={spanClassName}>
                <Button
                    disabled={disabled || loading}
                    onClick={onPress}
                    id={id}
                    className={`
                ${className}
                ${styles.button}
                ${icon && styles.icon}
                ${(size === "small") && styles.small}
                ${disabled && styles.disabled}
                ${((fourtuary)
                            ? styles.fourtuary
                            : (tertiary)
                                ? styles.tertiary
                                : (secondary)
                                    ? styles.secondary
                                    : (primary)
                                        ? styles.primary
                                        : styles.text
                        )}
            `}
                    variant="contained"
                    disableElevation={disableElevation}
                >
                    {
                        icon && icon
                    }
                    {
                        (loading || title) &&
                        <p className={(fontSize === "normal") ? styles.normal : styles.small}>
                            {loading
                                ?
                                <CircularProgress
                                    size={styles.loader}
                                    classes={{
                                        colorPrimary: styles.loader
                                    }}
                                />
                                :
                                title
                            }
                        </p>
                    }
                    {
                        rightIcon && rightIcon
                    }
                </Button>
            </span>
        </Tooltip>
    );
};

export default ButtonShared;