import { useMemo, useEffect } from "react";
import styles from "./model.module.scss";
import { useNavigate } from "react-router-dom";
import SelectShared from "../../../components/Select/Select";
import SellModal from "../../../components/SellModal/SellModal";
import { useToast } from "../../../helpers/hooks";
import { ModelPageProps } from "../../../interfaces/salePost";
import NoDataList from "../../../components/NoDataList/NoDataList";
import { images } from "../../../constants/images";
import { trackEvent } from "../../../config/firebase";

const Model = ({
    getModels,
    models,
    cleanGetModels,
    setModel,
    brands,
    plate,
    isFoundPlate
}: ModelPageProps) => {
    const navigate = useNavigate();
    const toast = useToast();

    const modelsNormalized: any = useMemo(() => {
        return models?.data && models?.data.map((model: any) => (
            {
                id: model?.model_id,
                name: model?.name
            }
        ))
    }, [models?.data]);

    const onPressNext = (model: any) => {
        setModel({id: model.id, label: model.name});
        navigate("/sell/car_detail");
    }

    useEffect(() => {
        if(models?.fetching === "failure"){
            toast("Ha ocurrido un error al traer los modelos");
            cleanGetModels();
        }
        if(models?.fetching === "success"){
            cleanGetModels();
        }
    }, [models?.fetching]);
    
    useEffect(() => {
        getModels(brands?.selected?.id);
    }, [brands?.selected]);

    useEffect(() => {
        if(!plate) return navigate("/sell");
    }, [plate]);

    useEffect(() => {
        trackEvent("venta-paso-modelo")
    },[])
    
    return (
        <SellModal 
            step={4} 
            title="Ingresa el modelo de tu auto"
            onClickBack={() => navigate("/sell/year")}
            className={styles.sellModal}
            isFoundPlate={isFoundPlate}
        >
            <div className={styles.inputsContainer}>
                {
                    (models?.fetching === "success" && modelsNormalized.length === 0) 
                    ?
                        <div className={styles.noModels}>
                            <NoDataList
                                title="No encontramos modelos para esta marca"
                                text="No hay modelos disponibles para esta marca actualmente, por favor regresa y elige otra marca u año"
                                image={images.noBuysPublications}
                                className={styles.noData}
                            />
                        </div>
                    :
                        <SelectShared
                            variant="scroll"
                            label="Escribe el modelo que buscas"
                            name="model"
                            onPressOption ={(value: any) => onPressNext(value)}
                            options={modelsNormalized}
                            loading={models?.fetching === "loading"}
                            loadingMessage="Cargando modelos"
                            className={styles.input}
                        />
                }
            </div>
        </SellModal>
    )
};

export default Model;