import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isModalOpen: false,
  isFirstLoad: true,
};

const modalMarketingSlice = createSlice({
  name: 'modalMarketing',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
    setFirstLoadFalse: (state) => {
      state.isFirstLoad = false;
    },
  },
});

export const { openModal, closeModal, setFirstLoadFalse } = modalMarketingSlice.actions;
export default modalMarketingSlice.reducer;
