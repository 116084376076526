import styles from "./footer.module.scss";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FooterProps } from "../../interfaces/components";
import { useWindowSize } from "use-hooks";
import { images } from "../../constants/images";
import { AnalyticsService } from "../../helpers/analytics";
import { trackEvent } from "../../config/firebase";

const Footer = ({
    user,
    searchType,
    setTypeReducer
}: FooterProps) => {
    const location = useLocation();
    const windowSize = useWindowSize();
    const navigate = useNavigate();

    const handlePressFilter = (value: string) => {
        if(value === ""){
            setTypeReducer([]);
            return;
        }
        if(value === 'van' || value === 'deportivo') setTypeReducer([]);
        else setTypeReducer([value]);
    }

    return (
        <footer className={styles.footerContainer}>
            <section className={styles.footerContent}>
                <div className={styles.leftFooter}>
                    <img src={images.isoLogoTipo} />
                    <p style={{color: "#595959", fontSize:  windowSize.width < 1024 ? "15px" : "16px", margin: "12px 0"}}>Un producto de Linzecar SPA®.</p>
                </div>
                <div className={styles.rightFooter}>
                    <div className={styles.rowFooter}>
                        <div style={{display:  windowSize.width < 1024 ? "none" : "flex", flexDirection: "row", gap: "32px", alignItems: "center", position:  windowSize.width < 1024 ? "relative" : "absolute", right: 0, left: 0, justifyContent: "center", bottom: windowSize.width > 1412 ? "80px" : "88px"}}>
                            <NavLink to='/search' onClick={() => AnalyticsService.trackButtonClick('buy', 'footer')} className={styles.aLink}>
                                Comprar
                            </NavLink>
                            <NavLink to={'/sell'} onClick={() => AnalyticsService.trackButtonClick('sell', 'footer')} className={styles.aLink}>
                                Vender
                            </NavLink>
                            <NavLink to={'/login'} className={styles.aLink}>
                                Ingresar
                            </NavLink>
                        </div>
                        <div className={styles.socialNetworksContainer}>
                            <img onClick={() => window.open("https://www.instagram.com/linzecar.chile?igsh=MWZtejMwZjlobjFsaQ==","_blank")} src={images.igIconFooter} />
                            <img onClick={() => window.open("https://www.facebook.com/Linzecar.chile/","_blank")} src={images.fbIconFooter} />
                            <img onClick={() => window.open("https://www.linkedin.com/company/linze-car/about/","_blank")} src={images.linkedinIconFooter} />
                        </div>
                    </div>
                    <div  style={{display: "flex", flexDirection: windowSize.width > 1412 ? "row" : "column", gap: windowSize.width > 1412 ? "24px" :"6px", alignItems: "center", position: windowSize.width < 1024 ? "relative" : "absolute", right: 0, left: 0, justifyContent: "center", bottom: windowSize.width < 1024  ? 0 :"32px",}}>
                        <p style={{color: "#595959", fontSize:  windowSize.width < 1024 ? "12px" : "16px", textAlign:  windowSize.width < 1024 ? "center" : "unset" }}>Copyright © 2023 Linze. Todos los derechos reservados</p>
                        <p style={{color: "#595959", display: windowSize.width > 1412 ?"block" : "none"  }}>|</p>
                        <p style={{color: "#595959", fontSize:  windowSize.width < 1024 ? "12px" : "16px"}}>Visita nuestros <span onClick={() => navigate("/terms")}><u style={{fontWeight: "500", cursor: "pointer"}}>términos y condiciones</u></span></p>
                    </div>
                </div>
                {windowSize?.width < 1024 ? <div style={{width: "100%", height: "1px", backgroundColor: "#E2E8F0", margin: "16px 0 8px 0"}}/> : null}
            </section>
        </footer>
    )
}
export default Footer;